import React from "react";
import MainCommon from "./MainCommon/MainCommon";

const App = () => {
  return (
    <>
      <MainCommon />
    </>
  );
};

export default App;
